<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="收货人信息" />

    <!-- 内容 -->
    <div class="padding-cu">
      <form>
        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="text"
            id="consignee_full_name"
            placeholder="consignee_full_name"
            v-model="consignee_full_name"
          />
          <label class="label" for="consignee_full_name"> 收货人 </label>
        </div>

        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="tel"
            id="consignee_phone_number"
            placeholder="consignee_phone_number"
            v-model="consignee_phone_number"
          />
          <label class="label" for="consignee_phone_number">
            收货人手机号
          </label>
        </div>
        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="text"
            id="area"
            placeholder="area"
            v-model="area"
            @focus="show_area = true"
            readonly
          />
          <label class="label" for="area"> 所在地区 </label>

          <van-action-sheet v-model="show_area">
            <van-area
              :area-list="area_list"
              @confirm="confirmArea"
              @cancel="cancelArea"
            />
          </van-action-sheet>
        </div>
        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="address"
            id="address"
            placeholder="address"
            v-model="address"
          />
          <label class="label" for="address"> 详细地址 </label>
        </div>

        <div class="margin-top-lg padding-bottom">
          ※为确保准确送达，请正确填写您的真实姓名、电话号码和地址。
        </div>

        <!--入力前-->
        <input
          v-if="!consignee_full_name || !consignee_phone_number || !area || !address"
          type="button"
          value="保存"
          class="input-button submit-before"
        />

        <!--入力後-->
        <input
          v-else
          type="button"
          value="保存"
          class="input-button submit-after"
          @click="onSubmit"
        />
      </form>
    </div>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { getShippingAddress, updateShippingAddress } from "../../https/http";

// vant弹窗提示
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
import util from "../../utils/util";

export default {
  name: "ShippingAddressEdit",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      consignee_full_name: "",
      consignee_phone_number: "",
      province: "",
      city: "",
      district: "",
      address: "",
      area_list: areaList,
      show_area: false,
    };
  },
  computed: {
    area() {
      if (!!this.province && this.city && this.district) {
        return this.province + " " + this.city + " " + this.district;
      } else {
        return "";
      }
    },
  },
  created() {
    getShippingAddress().then((res) => {
      this.consignee_full_name = res.data.consignee_full_name;
      this.consignee_phone_number = res.data.consignee_phone_number;
      this.province = res.data.province;
      this.city = res.data.city;
      this.district = res.data.district;
      this.address = res.data.address;
    });
  },
  mounted() {},
  methods: {
    confirmArea(event) {
      // 从 event 中获取省、市、区信息
      this.province = event[0].name;
      this.city = event[1].name;
      this.district = event[2].name;
      this.cancelArea();
    },

    cancelArea() {
      this.show_area = false;
    },
    onSubmit() {
      if (!util.checkPhone(this.consignee_phone_number)) {
        Toast.fail("收货人手机号格式不正确。");
        return;
      }
      let values = {
        consignee_full_name: this.consignee_full_name,
        consignee_phone_number: this.consignee_phone_number,
        province: this.province,
        city: this.city,
        district: this.district,
        address: this.address,
      };
      updateShippingAddress(values).then((res) => {
        if (res.data == "OK") {
          this.$router.back();
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
