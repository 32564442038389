<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="支付结果" />

    <!-- 内容 -->
    <div class="padding-cu">
      <div v-if="order_dat.status">
        <h1 class="margin-top-lg text-xxxl text-center">
          {{ order_dat.status == "PAID" ? "支付成功" : "支付失败" }}
        </h1>
        <!-- 订单信息 -->
        <div class="margin-top">
          <div>
            <span class="title-box">订单号:</span>
            <span>{{ order_dat.order_no }}</span>
          </div>

          <div class="margin-top-sm">
            <span class="title-box">创建时间:</span>
            <span>{{ order_dat.registration_date }}</span>
          </div>

          <div class="margin-top-sm">
            <span class="title-box">金额:</span>
            <span
              >{{ order_dat.total_text }}日元(¥约<span
                class="text-gold text-xs"
                >{{ order_dat.total_rmb_text }}</span
              >元)</span
            >
          </div>
        </div>
      </div>
      <div v-else class="margin-top-lg text-xxxl text-center">等待支付结果...</div>
      <form class="margin-top-xxl">
        <input
          type="button"
          value="回到首页"
          class="input-button submit-after"
          @click="toHome"
        />
      </form>
    </div>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";
import Footer from "../../components/Footer.vue";

// 接口
import { getOrderDetail } from "../../https/http";

// vant弹窗提示
export default {
  name: "OrderFinish",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      order_dat: [],
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    getOrderDetail({ order_id }).then((res) => {
      // 如果支付没有成功，则再请求一次支付结果，防止支付通知延迟
      if (res.data.order_dat.status != "PAID") {
        setTimeout(() => {
          getOrderDetail({ order_id }).then((res) => {
            this.order_dat = res.data.order_dat;
          });
        }, 2000);
      } else {
        this.order_dat = res.data.order_dat;
      }
    });
  },
  mounted() {},
  methods: {
    toHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
.title-box {
  display: inline-block;
  width: 4.5em;
  margin-right: 20px;
  font-size: 13px;
  color: #ccc;
}
</style>
