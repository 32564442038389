<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="注册" />

    <!-- 内容 -->
    <div class="padding-cu margin-top-xl">
      <form>
        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="text"
            id="full_name"
            placeholder="full_name"
            v-model="full_name"
          />
          <label class="label" for="full_name"> 姓名 </label>
        </div>
        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="tel"
            id="phone_number"
            placeholder="phone_number"
            v-model="phone_number"
          />
          <label class="label" for="phone_number"> 手机号 </label>
        </div>
        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="text"
            id="wxid"
            placeholder="wxid"
            v-model="wxid"
          />
          <label class="label" for="wxid"> 微信号 </label>
        </div>
        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="password"
            id="password"
            placeholder="password"
            v-model="password"
          />
          <label class="label" for="password"> 密码 </label>
        </div>

        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="password"
            id="password_confirm"
            placeholder="password_confirm"
            v-model="password_confirm"
          />
          <label class="label" for="password_confirm"> 密码(确认) </label>
        </div>

        <!-- 隐私政策 -->
        <div class="margin-top-lg flex">
          <van-checkbox v-model="is_privacy_checked" shape="square" checked-color="#fff">
            我已经阅读并同意
            <a class="text-gold text-underline" href="javascript:void(0);" @click="toPrivacy">隐私政策</a>
          </van-checkbox>
        </div>

        <!-- 注册按钮 -->
        <div class="margin-top">
          <!--入力前-->
          <input
            v-if="!full_name || !phone_number || !password || !password_confirm || !wxid || !is_privacy_checked"
            type="button"
            value="注册"
            class="input-button submit-before"
          />
          <!--入力後-->
          <input
            v-else
            type="button"
            value="注册"
            class="input-button submit-after"
            @click="onSubmit"
          />
        </div>
      </form>
    </div>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { userRegister } from "../../https/http";

// vant弹窗提示
import { Toast } from "vant";
import util from "../../utils/util";

export default {
  name: "Register",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      full_name: "",
      phone_number: "",
      password: "",
      password_confirm: "",
      wxid: "",
      is_privacy_checked: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit() {
      if (!util.checkPhone(this.phone_number)) {
        Toast.fail("手机号格式不正确。");
        return;
      }
      let values = {
        full_name: this.full_name,
        phone_number: this.phone_number,
        password: this.password,
        password_confirm: this.password_confirm,
        wxid: this.wxid,
      };
      if (values.password !== values.password_confirm) {
        Toast.fail("密码和密码(确认)输入不一致");
        return;
      }
      if (!!localStorage.getItem("cpno")) {
        values.cpno = localStorage.getItem("cpno");
      }
      if (!!localStorage.getItem("introducer")) {
        values.introducer = localStorage.getItem("introducer");
      }
      userRegister(values).then((res) => {
        if (res.data.status == "NG") {
          Toast.fail(res.data.msg);
        }
        if (res.data.status == "OK") {
          Toast.success(res.data.msg);
          let query = {}
          if(!!res.data.coupon_id) {
            query.coupon_id = res.data.coupon_id
          }
          setTimeout(() => {
            this.$router.push({ path: "/login", query });
          }, 1000);
        }
      });
    },
    /**
     * 隐私政策
     */
    toPrivacy() {
      this.$router.push({ path: "/privacy" });
    },
  },
};
</script>

<style scoped>
/deep/ .van-checkbox__label {
  color: #fff;
}

/deep/ .van-checkbox__icon--checked .van-icon {
  color: #333;
}
</style>
