<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar :key="refresh_navigationbar_key" />
    <!-- 标题栏 -->
    <TitleBar title="商品详情" />

    <!-- 内容 -->
    <!-- 轮播图或封面图 -->
    <van-swipe :autoplay="2000" indicator-color="#b9ac72">
      <van-swipe-item v-for="(image, index) in product.images" :key="index">
        <img :src="image" alt="kv" class="kv-img" />
      </van-swipe-item>
    </van-swipe>

    <div class="padding-cu">
      <!-- 产品标题 -->
      <div class="margin-top-xl text-xl text-bold">
        {{ product.title_cn ? product.title_cn : product.title_jp }}
      </div>
      <!-- 产品价格 -->
      <div class="margin-top-sm text-sm text-gray">
        {{ product.selling_price }}日元
      </div>
      <div class="margin-top-xs text-bold">
        ¥约<span class="text-lg text-gold">{{ product.selling_price_rmb }}</span
        >元
      </div>
      <!-- 分类 -->
      <div class="margin-top text-gray">
        <div
          class="margin-top-xs"
          v-for="(category, index) in product.category_list"
          :key="index"
        >
          <template v-for="(item, key) in category">
            <span @click="toProductListByCategory(item.id)">
              {{ item.title_cn }}
            </span>
            <van-icon
              v-if="key !== category.length - 1"
              name="arrow"
              custom-style="top:2px;"
              class="margin-lr-xxs"
            />
          </template>
        </div>
      </div>
      <!-- 图片和图片描述 -->
      <div class="margin-top">
        <div
          v-for="(item, index) in product.descriptions"
          :key="index"
          class="margin-top-sm"
        >
          <img
            :src="item.description_image"
            alt="description image"
            class="description-img"
          />
          <div
            v-if="!!item.description_text"
            class="margin-top-xxs text-pre-wrap"
          >
            {{ item.description_text }}
          </div>
        </div>
      </div>
      <!-- 商品描述 -->
      <div class="margin-top text-pre-wrap">
        {{ product.description_cn }}
      </div>
      <!-- 数量选择 -->
      <div
        v-if="!!product.skus && product.skus.length > 0"
        class="margin-top-xl flex align-center"
      >
        <span>数量</span>
        <van-stepper
          class="margin-left"
          v-model="sku_quantity"
          min="1"
          :max="product.skus[sku_index].stock"
          @change="selectQuantity"
        />
      </div>
      <!-- sku选择 -->
      <template v-if="!!product.skus && product.skus.length > 0">
        <div class="margin-top flex">
          <span>选择</span>
          <div class="margin-left text-bold">
            <div>
              {{
                product.skus[sku_index].spec_title_cn
                  ? product.skus[sku_index].spec_title_cn
                  : product.skus[sku_index].spec_title_jp
              }}
            </div>
            <div class="margin-top-xs">
              <span
                >¥约<span class="text-gold text-lg">{{
                  product.skus[sku_index].selling_price_rmb
                }}</span
                >元<span class="margin-left-xm text-xxs"
                  >（{{
                    product.skus[sku_index].selling_price_text
                  }}日元）</span
                ></span
              >
            </div>
          </div>
        </div>

        <div class="margin-top-xs flex flex-wrap align-center">
          <img
            v-for="(item, index) in product.skus"
            :key="index"
            :src="item.thumbnail_image"
            :class="{
              'sku-thumbnail margin-top-xs margin-right-sm': true,
              'sku-thumbnail-selected': index == sku_index,
            }"
            @click="selectSku(index)"
          />
        </div>
      </template>
      <!-- 购买按钮 -->
      <div class="margin-top">
        <!-- 入力フォーム -->
        <form>
          <input
            v-if="
              !!product.skus &&
              (product.skus.length < 1 || product.skus[sku_index].stock < 1)
            "
            type="button"
            value="售罄"
            class="input-button"
          />
          <input
            v-else
            type="button"
            value="加入购买车"
            class="input-button submit-after"
            @click="addCart"
          />
          <input
            v-if="this.$globalData.login_status !== 'MEMBER'"
            type="button"
            value="收藏"
            class="input-button"
            @click="toLogin"
          />
          <input
            v-else
            type="button"
            value="收藏"
            class="input-button submit-after"
            @click="addFavorite"
          />
        </form>
      </div>
    </div>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";
import Footer from "../../components/Footer.vue";

// 接口
import { getProductDetail, addCartProduct, addFavoriteProduct } from "../../https/http";

// vant弹窗提示
import { Toast } from "vant";

export default {
  name: "ProductDetail",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      product: [], // product详情信息

      sku_index: 0, // 被选中的sku序号
      sku_quantity: 1, // 被选中的sku的数量
      stock: 0, //库存量
      refresh_navigationbar_key: 0 // 初始 key 值
    };
  },
  created() {
    let product_id = this.$route.query.product_id;
    getProductDetail(product_id).then((res) => {
      this.product = res.data;
      this.stock = this.product.skus[this.sku_index].stock;
    });
  },
  mounted() {},
  methods: {
    toProductListByCategory(category_id) {
      this.$router.push({
        path: "/product_list_by_category",
        query: { category_id },
      });
    },

    /**
     * 选择sku数量
     */
    selectQuantity() {},

    /**
     * 选择sku型号
     */ selectSku(index) {
      this.sku_index = index;
      this.sku_quantity = 1;
    },

    /**
     * 加入购物车
     */
    addCart() {
      let product_sku_id = this.product.skus[this.sku_index].id;
      let quantity = this.sku_quantity;
      // 本地存储
      this.saveLocalCart(product_sku_id, quantity);
      if (!!this.$globalData.login_status) {
        //登录状态 数据库存储  TODO
        this.saveRemoteCart(product_sku_id, quantity);
      }
      Toast.success("加入购物车成功");
    },
    /**
     * 商品加入本地localStorage购物车
     */
    saveLocalCart(product_sku_id, quantity) {
      let cart = JSON.parse(localStorage.getItem("cart"));
      if (!cart) {
        cart = [];
      }
      cart.push({ product_sku_id, quantity });

      // 使用 reduce 来合并相同 product_sku_id 的对象
      let result = cart.reduce((acc, current) => {
        // 查找是否已经存在相同的 product_sku_id
        let existing = acc.find(
          (item) => item.product_sku_id === current.product_sku_id
        );
        if (existing) {
          // 如果存在，累加 quantity
          existing.quantity += current.quantity;
        } else {
          // 如果不存在，直接添加到结果数组
          acc.push(current);
        }
        return acc;
      }, []);
      localStorage.setItem("cart", JSON.stringify(result));
      this.$globalData.cart_num = result.length; // 修改全局变量
      this.refresh_navigationbar_key += 1; // 更新 key，触发重新渲染
    },
    /**
     * 商品加入服务器数据库购物车
     */
    saveRemoteCart(product_sku_id, quantity) {
      addCartProduct({ product_sku_id, quantity }).then((res) => {
        this.$globalData.cart_num = res.data.cart_num; // 修改全局变量
        this.refresh_navigationbar_key += 1; // 更新 key，触发重新渲染
        console.log("add cart product " + res.data.status);
      });
    },

    /**
     * 跳转登录页面
     */
    toLogin() {
      this.$globalData.after_login_path =
        "/product_detail?product_id=" + this.product.product_id
      this.$router.push({ path: "/login" });
    },
    /**
     * 加入收藏
     */
     addFavorite() {
      let product_id = this.product.product_id
      addFavoriteProduct({ product_id }).then((res) => {
        if(res.data == 'OK') {
          Toast.success("加入收藏成功");
        } else {
          Toast.fail("加入收藏失败");
        }
      });
    },
  },
};
</script>

<style scoped>
.kv-img {
  width: 100%;
  height: 420px;
  object-fit: cover;
}

.description-img {
  width: 100%;
}

.sku-thumbnail {
  width: 160px;
  height: 160px;
}

.sku-thumbnail-selected {
  border: 4px solid #b9ac72;
}
</style>
