<template>
  <div id="app" class="van-text-14 text-white">
    <transition name="fade" mode="out-in">
      <router-view v-if="!isLoading"></router-view>
    </transition>
  </div>
</template>

<script>
// 接口
import { checkLoginStatus } from "./https/http";
import util from "./utils/util";

export default {
  name: "App",
  data() {
    return {
      isLoading: true, // 初始状态为加载中
    };
  },
  created() {
    let cpno = this.$route.query.cpno;
    if (!!cpno) {
      localStorage.setItem("cpno", cpno);
    }
    let introducer = this.$route.query.introducer;
    if (!!introducer) {
      localStorage.setItem("introducer", introducer);
    }
    this.checkLoginStatus();
  },
  methods: {
    checkLoginStatus() {
      let user_session_key =
        sessionStorage.getItem("user_session_key") ??
        localStorage.getItem("user_session_key");

      if (!!user_session_key) {
        //向服务器查询用户是否存在，如果存在，则判定为登录状态
        checkLoginStatus({ user_session_key }).then((res) => {
          if (res.data) {
            this.$globalData.login_status = res.data.login_status; // 修改全局变量
            this.$globalData.cart_num = res.data.cart_num; // 修改全局变量
            this.isLoading = false;
          }
        });
      } else {
        let cart = JSON.parse(localStorage.getItem("cart"));
        if (!util.isEmpty(cart)) {
          this.$globalData.cart_num = cart.length; // 修改全局变量
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
#app {
  height: 100%;
}

/* 定义过渡效果的 CSS */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>