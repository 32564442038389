// 所有的请求都放在该目录
import instance from "../utils/request";
// 0. 通过user_session_key验证是否登录
export function checkLoginStatus(params) {
    return instance.post('/check_login_status.php', params)
}

//1. 获取首页数据列表
export function getTopInfo() {
    return instance.get('/get_top_info.php')
}

//2. 通过分类获取产品数据列表
export function getProductListByCategory(category_id) {
    return instance.get('/get_product_list_by_category.php?category_id=' + category_id)
}

//3. 通过关键词搜索获取产品数据列表
export function getProductListByKeyword(keyword) {
    return instance.get('/get_product_list_by_keyword.php?keyword=' + keyword)
}

//4. 获取商品详情数据
export function getProductDetail(product_id) {
    return instance.get('/get_product_detail.php?product_id=' + product_id)
}

//5. sku加入购物车
export function addCartProduct(params) {
    return instance.post('/add_cart_product.php', params)
}

//6. 获取购物车内商品信息
export function getCartProduct() {
    return instance.get('/get_cart_product.php')
}

//7. 获取通过本地cart获取购物车信息
export function getCartProductByLocal(params) {
    return instance.post('/get_cart_product_by_local.php', params)
}

//8. 用户注册
export function userRegister(params) {
    return instance.post('/user_register.php', params)
}

//9. 用户登录
export function userLogin(params) {
    return instance.post('/user_login.php', params)
}

//10. 把本地购物车上传到服务器
export function uploadLocalCart(params) {
    return instance.post('/upload_local_cart.php', params)
}

//11. 删除购物车内商品
export function delCartProduct(params) {
    return instance.post('/del_cart_product.php', params)
}

//12. 检查购物车内商品是否有库存
export function checkCart(params) {
    return instance.post('/check_cart.php', params)
}

//13. 确认订单
export function confirmOrder(params) {
    return instance.post('/confirm_order.php', params)
}

//14. 访客购买时注册guest用户并同步购物车
export function guestRegister(params) {
    return instance.post('/guest_register.php', params)
}

//15. 更新购物车内商品数量
export function updateCartProductQuantity(params) {
    return instance.post('/update_cart_product_quantity.php', params)
}

//16. 配送先住所新规，编辑
export function updateShippingAddress(params) {
    return instance.post('/update_shipping_address.php', params)
}

//17. 获取用户配送先住
export function getShippingAddress() {
    return instance.get('/get_shipping_address.php')
}

//18. 创建订单
export function createOrder(params) {
    return instance.post('/create_order.php', params)
}

//19. 创建订单
export function getUserInfo() {
    return instance.get('/get_user_info.php')
}

//20. 产品加入收藏
export function addFavoriteProduct(params) {
    return instance.post('/add_favorite_product.php', params)
}

//21. 获取收藏内商品信息
export function getFavoriteProduct() {
    return instance.get('/get_favorite_product.php')
}

//22. 删除收藏内商品
export function delFavoriteProduct(params) {
    return instance.post('/del_favorite_product.php', params)
}

//23. 订单一览
export function getOrderList() {
    return instance.get('/get_order_list.php')
}

//24. 获取单个订单详情
export function getOrderDetail(params) {
    return instance.post('/get_order_detail.php', params)
}

//25. 更新订单状态
export function updateOrderStatus(params) {
    return instance.post('/update_order_status.php', params)
}

//26. 获取注册优惠劵
export function getRegisterCoupon(params) {
    return instance.post('/get_register_coupon.php', params)
}