<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar/>

    <!-- 内容 -->
    <div class="margin-top-sm">
      <!-- 搜索框 -->
      <div class="padding-cu">
        <van-search
            v-model="keyword"
            shape="round"
            placeholder="搜索所有项目"
            @search="toProductListByKeyword"
        />
      </div>

      <!-- 轮播图或封面图 -->
      <div class="margin-top-sm">
        <van-swipe :autoplay="2000" indicator-color="#b9ac72">
          <van-swipe-item v-for="(kv, index) in kv_list" :key="index">
            <img :src="kv.image" alt="kv" class="kv-img"/>
          </van-swipe-item>
        </van-swipe>
      </div>

      <!-- category -->
      <div class="padding-cu margin-top-sm category-section">
        <h2><img src="../../assets/images/title-category.svg" alt="Category"></h2>
        <div class="flex flex-wrap margin-top category-wrap">
          <div v-for="(category, index) in category_list" :key="index">
            <div
                class="category-box"
                @click="toProductListByCategory(category.id)"
            >
              <img :src="category.image_path" alt="category_img" class="category-img"/>
            </div>
            <div class="margin-top-sm text-center text-sm">{{ category.title_cn }}</div>
          </div>
        </div>
      </div>

      <!-- ブランドストーリー -->
      <div class="padding-cu margin-top-xxl category-section">
        <!-- <h2><img src="../../assets/images/title-category.svg" alt="Category"></h2> -->
        <div class="margin-top text-left">
          <p class="paragraph">アイリー製薬株式会社</p>
          <p class="paragraph">IREY Pharmacy Inc. 从经营药房起步，通过与再生医疗诊所与日本顶尖大学的合作研究，把尖端医疗带进生活，用循证医学，见证过的品质为您的健康和容颜提供最佳的理想服务</p>
          <p class="paragraph">IREY Premium系列产品是利用专利提纯技术的干细胞培养上清液，用医疗级别的品质，激发人体自身的免疫力和自愈力。</p>
          <p class="paragraph">在市场上充斥着低质量产品和过度宣传的环境下，IREY制药公司专注研发技术，为用户提供安全有效的高品制产品。</p>
          <p class="paragraph">&nbsp;</p>
          <p class="paragraph text-right">董事长青島隆</p>
        </div>
      </div>

      <!-- recommend-product -->
      <div class="padding-cu margin-top category-section">
        <h2><img src="../../assets/images/title-products.svg" alt="recommend_product_img"></h2>
        <div class="flex flex-wrap margin-top product-wrap">
          <div v-for="(product, index) in recommend_product_list" :key="index">
            <ProductIntroColumn :product="product"/>
          </div>
        </div>
      </div>
    </div>

    <!-- 尾部 -->
    <Footer/>
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import ProductIntroColumn from "../../components/ProductIntroColumn.vue";

// 接口
import { getTopInfo } from "@/https/http";

export default {
  name: "Home",
  components: {
    NavigationBar,
    Footer,
    ProductIntroColumn,
  },
  data() {
    return {
      keyword: "", //搜索关键字
      kv_list: [], // kv列表
      category_list: [], // category列表
      recommend_product_list: [], // product列表
    };
  },
  created() {
    getTopInfo().then((res) => {
      this.kv_list = res.data.kv_list;
      this.category_list = res.data.category_list;
      this.recommend_product_list = res.data.recommend_product_list;
    });
  },
  mounted() {
  },
  methods: {
    toProductListByCategory(category_id) {
      this.$router.push({
        path: "/product_list_by_category",
        query: {category_id},
      });
    },
    toProductListByKeyword() {
      let keyword = this.keyword;
      this.$router.push({
        path: "/product_list_by_keyword",
        query: {keyword},
      });
    },
  },
};
</script>

<style scoped>
.van-search {
  padding: 0;
  background-color: transparent;
}

.kv-img {
  width: 100%;
  height: 420px;
  object-fit: cover;
}

/* カテゴリー */
.category-section {
  text-align: center;
}

.category-section h2 {
  font-size: 1.5rem;
  margin-bottom: 0.1rem;
  position: relative;
  display: inline-block;
}

.category-section h2::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #B9AC72;
  margin: 0 auto 0;
}

.category-wrap {
  gap: 0.8rem;
}

.category-box {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: white;

  display: flex;
  justify-content: center;
  align-items: center;
}

.category-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.product-wrap {
  gap: 0.53rem;
}

.paragraph {
  line-height: 1.5em;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
</style>
