<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar :title="category_mst.title_cn" />

    <!-- 内容 -->
    <div class="margin-top padding-cu">
      <!-- product -->
      <div v-if="product_list.length > 0" class="flex flex-wrap product-wrap">
        <div v-for="(product, index) in product_list" :key="index">
          <ProductIntroColumn :product="product" />
        </div>
      </div>
      <van-empty v-else image="search" description="该分类尚无产品 敬请期待" />
    </div>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";
import Footer from "../../components/Footer.vue";
import ProductIntroColumn from "../../components/ProductIntroColumn.vue";

// 接口
import { getProductListByCategory } from "../../https/http";

export default {
  name: "ProductListByCategory",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
    ProductIntroColumn,
  },
  data() {
    return {
      category_mst: {}, // 分类信息
      product_list: [], // product列表
    };
  },
  created() {
    let category_id = this.$route.query.category_id;
    getProductListByCategory(category_id).then((res) => {
      this.category_mst = res.data.category_mst;
      this.product_list = res.data.product_list;
    });
  },
  mounted() {},
};
</script>

<style scoped>
.product-wrap {
  gap: 0.53rem;
}
</style>
