<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />

    <!-- 搜索框 -->
    <div class="padding-cu margin-top-sm">
      <van-search
        v-model="keyword"
        shape="round"
        placeholder="搜索所有项目"
        @search="searchKeyword"
      />
    </div>

    <!-- 标题 -->
    <div class="text-lg title-bar ">
      搜索"<span class="text-gold">{{ keyword }}</span>" (全{{ product_list.length }}商品)
    </div>

    <!-- 内容 -->
    <div class="margin-top padding-cu">
      <!-- product -->
      <div v-if="product_list.length > 0" class="flex flex-wrap product-wrap">
        <div v-for="(product, index) in product_list" :key="index">
          <ProductIntroColumn :product="product" />
        </div>
      </div>

      <van-empty v-else image="search" description="要不换个关键词试试" />
    </div>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import NavigationBar from "../../components/NavigationBar.vue";
import Footer from "../../components/Footer.vue";
import ProductIntroColumn from "../../components/ProductIntroColumn.vue";

// 接口
import { getProductListByKeyword } from "../../https/http";

export default {
  name: "ProductListByKeyword",
  components: {
    NavigationBar,
    Footer,
    ProductIntroColumn,
  },
  data() {
    return {
      keyword: "", // 搜索关键词
      product_list: [], // product列表
    };
  },
  created() {
    this.keyword = this.$route.query.keyword;
    getProductListByKeyword(this.keyword).then((res) => {
      this.product_list = res.data.product_list;
    });
  },
  mounted() {},
  methods: {
    searchKeyword(){
      getProductListByKeyword(this.keyword).then((res) => {
      this.product_list = res.data.product_list;
    });
    }
  }
};
</script>

<style scoped>
.van-search {
  padding: 0;
  background-color: transparent;
}

.product-wrap {
  gap: 0.53rem;
}

.title-bar {
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid #545b60;
}
</style>
