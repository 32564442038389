import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/reset.css'
import '@/assets/css/custom.css'
import Vant from 'vant';
import 'vant/lib/index.css';
// 引入 amfe-flexible
import 'amfe-flexible';
import router from './router'

Vue.use(Vant);

Vue.config.productionTip = false
// 定义全局变量
const globalData = Vue.observable({
  login_status: '',
  after_login_path: '/',
  cart_num: 0,
});

// 将全局状态挂载到 Vue.prototype 上，使其在全局可用
Vue.prototype.$globalData = globalData;

new Vue({
  router,  // 将路由挂载到 Vue 实例
  render: h => h(App),
}).$mount('#app')
