<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="订单详情" />

    <!-- 内容 -->
    <div class="padding-cu">
      <!-- 商品信息 -->
      <div class="border-bottom-gray">
        <ProductIntroRow
          v-for="(product, index) in order_product_list"
          :key="index"
          :product="product"
        />
      </div>
      <!-- 收货信息 -->
      <div class="border-bottom-gray padding-top-lg padding-bottom-lg">
        <div>
          <span class="title-box">收货人:</span>
          <span
            >{{ order_dat.consignee_full_name
            }}<span class="margin-left-xs">{{
              order_dat.consignee_phone_number
            }}</span></span
          >
        </div>
        <div class="margin-top-sm">
          <span class="title-box">收货地址:</span>
          <span v-if="order_dat.province == order_dat.city">{{ order_dat.province }}{{ order_dat.district }}{{ order_dat.address }}</span>
          <span v-else>{{ order_dat.province }}{{ order_dat.city }}{{ order_dat.district }}{{ order_dat.address }}</span>
        </div>
        <div class="margin-top-sm">
          <span class="title-box">配送方式:</span>
          <span>{{ order_dat.logistics_type }}</span>
        </div>
      </div>
       <!-- 商品和运费信息 -->
       <div class="border-bottom-gray padding-top-lg padding-bottom-lg">
        <div>
          <span class="title-box">商品总价:</span>
          <span
            >{{ order_dat.subtotal_text
            }}日元(¥约<span class="text-gold text-xs">{{
              order_dat.subtotal_rmb_text
            }}</span>元)</span
          >
        </div>
        <div class="margin-top-sm">
          <span class="title-box">运费:</span>
          <span
            >{{ order_dat.shipping_fee_text
            }}日元(¥约<span class="text-gold text-xs">{{
              order_dat.shipping_fee_rmb_text
            }}</span>元)</span
          >
        </div>
        <div v-if="order_dat.register_coupon_discount" class="margin-top-sm">
          <span class="title-box">优惠劵:</span>
          <span
            >-{{ order_dat.register_coupon_discount_text
            }}日元(¥约-<span class="text-gold text-xs">{{
              order_dat.register_coupon_discount_rmb_text
            }}</span>元)</span
          >
        </div>
        <div class="margin-top-sm">
          <span class="title-box">合计:</span>
          <span
            >{{ order_dat.total_text
            }}日元(¥约<span class="text-gold text-xs">{{
              order_dat.total_rmb_text
            }}</span>元)</span
          >
        </div>
      </div>
       <!-- 订单信息 -->
       <div class="border-bottom-gray padding-top-lg padding-bottom-lg">
        <div>
          <span class="title-box">订单号:</span>
          <span>{{ order_dat.order_no }}</span>
        </div>
      
        <div class="margin-top-sm">
          <span class="title-box">创建时间:</span>
          <span>{{ order_dat.registration_date }}</span>
        </div>
        <div class="margin-top-sm">
          <span class="title-box">付款时间:</span>
          <span>{{ order_dat.payment_date }}</span>
        </div>
        <div class="margin-top-sm">
          <span class="title-box">发货时间:</span>
          <span>{{ order_dat.shipping_date }}</span>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";
import Footer from "../../components/Footer.vue";
import ProductIntroRow from "../../components/ProductIntroRow.vue";

// 接口
import { getOrderDetail } from "../../https/http";

// vant弹窗提示
import { Dialog, Toast } from "vant";

export default {
  name: "OrderDetail",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
    ProductIntroRow,
  },
  data() {
    return {
      order_dat: [],
      order_product_list: [],
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    getOrderDetail({ order_id }).then((res) => {
      console.log(res);
      this.order_dat = res.data.order_dat;
      this.order_product_list = res.data.order_product_list;
    });
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.title-box {
  display: inline-block;
  width: 4.5em;
  margin-right: 20px;
  font-size: 13PX;
  color: #ccc;
}
</style>
