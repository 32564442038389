<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />

    <!-- 标题栏 -->
    <TitleBar title="隐私政策" />

    <!-- 内容 -->
    <div class="padding-cu margin-top-xl">
      <p class="paragraph">文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">1. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">2. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">3. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">4. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">5. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">6. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">7. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">8. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
      <p class="paragraph">9. 文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字。</p>
    </div>

    <!-- 尾部 -->
    <Footer />

  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口

// vant弹窗提示

export default {
  name: "Privacy",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() { },
  methods: {
  },
};
</script>

<style scoped>
.paragraph {
  line-height: 1.5em;
  margin-bottom: 1em;
}
</style>
